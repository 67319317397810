<template>
  <div>
    <van-swipe>
      <van-swipe-item>
        <img style="width: 100%" src="@/assets/aboutUs/banner-1.png" />
      </van-swipe-item>
    </van-swipe>

    <div class="content-body">
      <div style="display: flex">
        <span class="big-title" style="flex: 1">
          {{ $t('message')['home.companyIntroduce'] }}
        </span>
      </div>

      <van-divider
        :style="{
          color: '#ebedf0',
          borderColor: '#ebedf0',
          padding: '0 5px',
        }"
      ></van-divider>

      <div v-if="company && company.introduce" class="info-bg">
        <p class="info-text" v-for="item in company.introduce" :key="item">
          {{ item }}
        </p>

        <p class="title">
          {{ $t('message')['home.productFeature'] }}
        </p>

        <p class="info-text" v-for="item in company.feature" :key="item">
          {{ item }}
        </p>
      </div>
    </div>

    <div class="content-body">
      <div style="display: flex">
        <span class="big-title" style="flex: 1">
          {{ $t('message')['aboutUs.companyCulture'] }}
        </span>
      </div>

      <van-divider
        :style="{
          color: '#ebedf0',
          borderColor: '#ebedf0',
          padding: '0 5px',
        }"
      ></van-divider>

      <div v-if="company && company.culture" class="info-bg">
        <p class="info-text" v-for="item in company.culture" :key="item">
          {{ item }}
        </p>
      </div>
    </div>

    <div class="content-body">
      <div style="display: flex">
        <span class="big-title" style="flex: 1">
          {{ $t('message')['aboutUs.contactUs'] }}
        </span>
      </div>

      <van-divider
        :style="{
          color: '#ebedf0',
          borderColor: '#ebedf0',
          padding: '0 5px',
        }"
      ></van-divider>

      <div v-if="company && company.culture" class="info-bg">
        <p style="display: flex">
          <span class="contact-us-title">
            {{ $t('message')['aboutUs.phone'] }}：
          </span>
          <span class="contact-us-info">{{ baseInfo.phone }}</span>
        </p>

        <p style="display: flex">
          <span class="contact-us-title">QQ：</span>
          <span class="contact-us-info">{{ baseInfo.qq }}</span>
        </p>

        <p style="display: flex">
          <span class="contact-us-title">
            {{ $t('message')['aboutUs.email'] }}：
          </span>
          <span class="contact-us-info">{{ baseInfo.email }}</span>
        </p>

        <p style="display: flex">
          <span class="contact-us-title">
            {{ $t('message')['aboutUs.address'] }}：
          </span>
          <span class="contact-us-info">{{ baseInfo.address }}</span>
        </p>

        <div style="text-align: center; padding-bottom: 15px">
          <img
            style="width: 150px; height: 150px"
            alt="company_contact"
            src="@/assets/shop-qr.png"
          />
          <p style="color: #4d4d4d; font-size: 0.8em; margin: 0">
            扫描二维码进入宏佳电子淘宝店
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataZH, dataEN } from '@/utils/data'
import { getLanguage } from '@/utils/languageUtils'
export default {
  name: 'AboutUs',
  data() {
    return {
      company: dataZH.company,
      baseInfo: dataZH.baseInfo,
    }
  },
  created() {
    this.changeLanguage(getLanguage(this.$route))
  },
  methods: {
    // 更改语言
    changeLanguage(language) {
      this.company = language === 'enUS' ? dataEN.company : dataZH.company
      this.baseInfo = language === 'enUS' ? dataEN.baseInfo : dataZH.baseInfo
    },
  },
  watch: {
    '$i18n.locale': function (val) {
      this.changeLanguage(val)
    },
  },
}
</script>

<style lang="less" scoped>
.content-body {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
}

.big-title {
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
  // margin-bottom: 10px;
}

.title {
  font-size: 1em;
  font-weight: bold;
  color: #333;
  padding: 0 5px;
}

.info-bg {
  padding: 0 10px;
}

.info-text {
  color: #4d4d4d;
  font-size: 0.9em;
  text-indent: 2em;
  line-height: 1.4em;
}

.contact-us-title {
  color: #4d4d4d;
  font-size: 0.9em;
  line-height: 1.4em;
  width: 50px;
}

.contact-us-info {
  color: #4d4d4d;
  font-size: 0.9em;
  line-height: 1.4em;
  flex: 1;
}
</style>
